<template>
    <el-form-item :label="english?(item.title_en || item.title):item.title + ':'"
                  :prop="field" v-show="item.type !== 'na'">
        <el-input v-if="['text','textarea','password'].includes(item.type)"
                  v-model="single[field]"
                  :placeholder="english?(item.placeholder_en || item.placeholder):item.placeholder"
                  :disabled="item.readonly"
                  :type="item.type == 'password'? 'text' : item.type"
                  :size="item.size?item.size:'small'"
                  @blur="checkBlur(field,item.blur)"
        >
        </el-input>
        <el-input-number v-if="'number' === item.type"
                         controls-position="right"
                         v-model="single[field]"
                         :placeholder="english?(item.placeholder_en || item.placeholder):item.placeholder"
                         :disabled="item.readonly"
                         style="width: 100%"
                         :size="item.size?item.size:'small'">
        </el-input-number>
        <el-select v-if="'select'==item.type"
                   clearable filterable
                   v-model="single[field]"
                   :placeholder="english?(item.placeholder_en || item.placeholder):item.placeholder"
                   :disabled="item.readonly"
                   :size="item.size?item.size:'small'"
                   :multiple="'true'==item.multiple"
                   :style="{width:'100%'}">
            <el-option v-for="(option,key) in item.options"
                       :label="Array.isArray(item.options)?option.value:option"
                       :value="(Array.isArray(item.options)?option.key:key)+''"
                       :key="(Array.isArray(item.options)?option.key:key)+''"></el-option>
        </el-select>
        <el-autocomplete v-if="'autocomplete'==item.type"
                         v-model="single[field]"
                         :placeholder="english?(item.placeholder_en || item.placeholder):item.placeholder"
                         :disabled="item.readonly"
                         :size="item.size?item.size:'small'"
                         :fetch-suggestions="makeFetchSuggestions(item)"
                         :style="{width:'100%'}">
        </el-autocomplete>
        <el-switch v-if="'switch'==item.type" v-model="single[field]"
                   :active-value="item.active || '1'"
                   :inactive-value="item.inactive || '0'"
                   :disabled="item.readonly"
                   :size="item.size?item.size:'small'">
        </el-switch>
        <el-date-picker
            v-if="'date'==item.type" v-model="single[field]"
            :disabled="item.readonly"
            :size="item.size?item.size:'small'"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder='("选择时间")'>
        </el-date-picker>
        <el-time-select
            v-if="'time'==item.type" v-model="single[field]"
            :disabled="item.readonly"
            :size="item.size?item.size:'small'"
            value-format="hh:mm"
            :picker-options="{ start: '06:00',step: '00:05',end: '23:45'}"
            :placeholder='("选择时间")'>
        </el-time-select>
        <picture-upload v-if="'picture' ===item.type"
                        :size="item.size || 'small'"
                        :multiple="!!item.multiple"
                        :list.sync='single[field]'
                        :bgcolor="item.bgcolor"
                        :placeholder="item.placeholder"
        >
        </picture-upload>
        <single-file-upload v-if="'attachment' === item.type"
                            :upload_url="item.url"
                            v-model="single[field]"
                            :extensions="item.extensions"
                            :disabled="item.disable"
                            :file-size="item.size"
                            :title="item.placeholder || '请上传附件'"
        >
        </single-file-upload>
        <el-date-picker v-if="'date-range' === item.type"
                        v-model="single[field]"
                        type="daterange"
                        :range-separator="('至')"
                        :start-placeholder="('开始日期')"
                        value-format="yyyy-MM-dd"
                        :end-placeholder="('结束日期')">
        </el-date-picker>
        <el-cascader v-if="'cascade' === item.type"
                     v-model="single[field]"
                     :placeholder="english?(item.placeholder_en || item.placeholder):item.placeholder"
                     :disabled="item.readonly"
                     :size="item.size?item.size:'small'"
                     :options="item.options"
                     :props="item.props"
                     filterable
                     :style="{width:'100%'}">
        </el-cascader>
        <el-row v-if="'time-range'==item.type">
            <el-time-select
                v-model="single[field][0]"
                :disabled="item.readonly"
                :size="item.size?item.size:'small'"
                value-format="hh:mm"
                :picker-options="{ start: '06:00',step: '00:05',end: '23:45',maxTime:single[field][1]}"
                :placeholder='("开始时间")'>
            </el-time-select>
            至
            <el-time-select
                v-model="single[field][1]"
                :disabled="item.readonly"
                :size="item.size?item.size:'small'"
                value-format="hh:mm"
                :picker-options="{ start: '06:00',step: '00:05',end: '23:45', minTime:single[field][0]}"
                :placeholder='("结束时间")'>
            </el-time-select>
        </el-row>

        <div class="el-textarea el-input--small" v-if="'html' === item.type" style="height: 36px;">
            <div v-html="single[field]" class="el-textarea__inner" style="min-height: 31px;"></div>
        </div>
        <div class="el-textarea el-input--small" v-if="'link' === item.type" style="height: 36px;">
            <a :href="single[field]" class="el-textarea__inner" style="min-height: 31px;">{{ single[field] }}</a>
        </div>
    </el-form-item>
</template>

<script>
import PictureUpload from "./picture-upload";
import SingleFileUpload from "@/components/single-file-upload";

export default {
    name: "front-single-form-editor",
    components: {SingleFileUpload, PictureUpload},
    props: {
        single: {
            type: Object,
            default() {
                return {}
            }
        },
        field: {
            type: String,
            default() {
                return ""
            }
        },
        item: {
            type: Object,
            default() {
                return {}
            },
        }
    },
    computed: {
        english() {
            return false;
        },
    },
    methods: {
        makeFetchSuggestions(item) {
            return function autoCompleteFetch(a, cb) {
                cb(item.options.map((i) => {
                    return {value: i, label: i};
                }));

            };
        },
        checkBlur(field, blur) {
            if (blur && blur.action && blur.target) {
                this.$api(blur.action, {data: this.single[field]}, 1, 1).then(res => {
                    this.single[blur.target] = res.data.data;
                    this.$forceUpdate();
                });
            }
        }
    },

}
</script>

<style scoped>

</style>
